.price__section {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 text-align: center;
  width: 100%;
}

.price__title {
  padding-top: 10px;
}

.price__container {
  width: 350px;
  padding: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@include for-tablet-up {
  .price__container {
    width: 700px;
  }

  .price__text{
    font-size: $font-size-large;
    font-weight: 500;
  }
}

@include for-desktop {

  .price__container {
    width: 900px;
  }

  .price__title {
    padding-top: 10px;
  }

  .price__text{
    font-size: 23px;
    font-weight: 500;
    padding-bottom: 10px;
  }
}

@include for-wide-desktop {
  .price__container {
    width: 1050px;
  }


  .price__box {
    height: 150px;
    margin: 40px 70px;
  }
}