$f-main-light: 'Montserrat', sans-serif; // (waga: 300)
$f-main-regular: 'Montserrat', sans-serif; // (waga: normal/400)
$f-main-medium: 'Montserrat', sans-serif; // (waga: 500)
$f-main-semi-bold: 'Montserrat', sans-serif; // (waga: 600)
$f-main-bold: 'Montserrat', sans-serif; // (waga: bold/700)

$f-detal-light: 'Playfair Display', serif; // (waga: 300)
$f-detal-regular: 'Playfair Display', serif; // (waga: normal/400)
$f-detal-medium: 'Playfair Display', serif; // (waga: 500)
$f-detal-semi-bold: 'Playfair Display', serif; // (waga: 600)
$f-detal-bold: 'Playfair Display', serif; // (waga: bold/700)

// Rozmiary czcionek
$font-size-small: 14px; 
$font-size-medium: 16px; 
$font-size-semi-large: 18px; 
$font-size-large: 20px; 


$header-size-small: 16px; 
$header-size-medium: 25px; 
$header-size-semi-large: 33px;
$header-size-large: 40px; 
$header-size-extra-large: 50px; 

/*
Propozycja 1:

#f-main: Roboto
#f-detal: Raleway

Propozycja 2:

#f-main: Open Sans
#f-detal: Lora

Propozycja 3:

#f-main: Montserrat
#f-detal: Playfair Display

Propozycja 4:

#f-main: Poppins
#f-detal: Merriweather
*/