@import './config/colors';
@import './config/breakpoints';
@import './config/fonts';


.info__container {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.info__header {
  font-family: $f-detal-bold;
  color: $c-accent;
  margin-bottom: 20px;
  width: 330px;
  text-align: center;
}

.info__paragraph {
  width: 330px;
  font-family: $f-main-regular;
  font-size: $font-size-medium;
  margin-bottom: 20px;
  text-align: justify;
}

@include for-tablet-up {

  .info__container {
    width: 700px;
  }

  .info__header {
    width: 500px;
  }

  .info__paragraph {
    font-size: $font-size-semi-large;
    width: 600px;
  }
}

@include for-desktop-up {

  .info__container {
    width: 900px;
  }

  .info__header {
    width: 700px;
  }

  .info__paragraph {
    margin-top: 20px;
    width: 900px;
  }
}

@include for-wide-desktop {

  .info__container {
    width: 1050px;
  }

  .offer-info-paragraph {
    margin-top: 50px;
    padding-inline: 150px;
  }
}