@import './config/colors';
@import './config/breakpoints';
@import './config/fonts';

.offer-section {
  background-color: $c-theme;
  color: $c-black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 30px;
}

.offer-header {
  margin: 25px;
  font-size: $header-size-medium;
}


@include for-tablet-up {
  .offer-header {
    font-size: $header-size-semi-large;
   }
}