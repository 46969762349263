@import './config/colors';
@import './config/fonts';
@import './config/breakpoints';

.contact-section {
  padding-top: 30px;
}

.contact-header {
  margin-top: 25px;
  text-align: center;
  font-size: $header-size-medium;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.contact-box-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
  }
}

.contact-box-link {
  display: flex;
}

.contact-link-p {
  padding-bottom: 7px;
  font-weight: 500;
  font-size: $font-size-medium;
  color: $c-black;
}

.contact-box-link:hover {
  text-decoration: underline;
}

.paragraph-online {
  font-weight: 500;
  text-align: center;
  width: 340px;
  font-size: $font-size-medium;
}

.online-link {
  font-weight: 500;
  font-size: $font-size-medium;
}


.icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
}

.email-icon {
  background-image: url('../assets/email.png');
}

.phone-icon {
  background-image: url('../assets/phone.png');
}

.map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-section {
  padding-top: 20px;
  padding-bottom: 30px;
}

.map-box {
  width: 100%;
}

.map {
  width: 100%;
  padding-bottom: 30px;
}

.map iframe {
  width: 100%;
  height: 300px;
}

.map-paragraph {
  font-weight: 500;
  font-size: $font-size-medium;
  width: 360px;
  padding-bottom: 20px;
  text-align: center;
}

.contact-color {
  height: 150px;
  width: 100%;
  background: linear-gradient(0deg, rgb(87, 80, 177) 0%, rgba(71, 162, 253, 0.778) 50%, rgba(250, 244, 244, 0.108) 100%);
}



@include for-tablet-up {
  .contact-container {
    width: 100%;
  }

  .contact-header {
    font-size: $header-size-semi-large;
  }

  .map-section {
    display: flex;
    justify-content: space-between;
  }

  .map-box {
    padding-inline: 10px;
  }

  .map {
    padding-bottom: 70px;
  }

  .map-paragraph {
    width: 400px;
    color: $c-black;
    font-size: $font-size-large;
  }

  .map iframe {
    width: 100%;
    height: 350px;
  }

  .contact-link {
    white-space: nowrap;
    text-align: left;
  }

  .contact-link-p {
    font-size: $font-size-large;
  }

  .paragraph-online {
    font-size: $font-size-large;
  }

  .online-link {
    font-size: $font-size-large;
  }

  .online-link {
    font-size: $font-size-large;
  }


}

@include for-desktop {

  .contact-link {
    margin: 0 60px;
    min-width: 150px;
    white-space: nowrap;
  }

  .map-box {
    padding-inline: 20px;
  }

  .map iframe {
    height: 450px;
    width: 500px;
    border: none;
  }

  .map-paragraph {
    padding-bottom: 20px;
    padding-inline: 30px;
    width: 430px;
    font-size: $font-size-large;
    font-weight: 600;
  }

  .contact-link-p {
    font-size: $font-size-large;
  }

  .paragraph-online {
    font-size: $font-size-large;
    width: 400px;
  }

  .online-link:hover {
    text-decoration: underline;
  }
}

@include for-wide-desktop {

  .map-paragraph {
    padding-bottom: 30px;
  }

  .map iframe {
    height: 450px;
    padding: 5px;
    width: 600px;
  }

  .contact-box {
    padding: 10px;
    text-align: ce;
  }

  .contact-color {
    height: 300px;
  }
}