@import './config/colors';
@import './config/breakpoints';
@import './config/fonts';

.services-section {
  padding-block: 30px;
}

.services {
  width: 100%;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-title {
  margin:25px;
  text-align: center;
  font-size: $header-size-medium; 
}

.services-container {  
  width: 350px;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-box {  
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
}

.services-box-text {
  padding-bottom: 10px;
  text-align: start;
  font-size: 14px;
}

.services-box-text-last {
  padding-bottom: 25px;
  text-align: justify;
  font-size: 14px;
} 

.services-box-text-author {
  width: 320px;
  padding-top: 10px;
  text-align: end;
  font-size: 12px;
  font-style: italic;
}

.services-box-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height:370px;
  width: 320px;
  margin-bottom: 10px;
}

.image-1 {
  background-image: url('../assets/image-1.avif');
}

.image-2 {
  background-image: url('../assets/image-2.avif');
}

@include for-tablet-up {

  .services-container {  
    width: 700px;
  }

  .services-title {
    font-size: $header-size-semi-large; 
  }

  .services-box {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    align-items: start; 
    gap: 10px; 
    width: 700px;
    padding-inline: 10px;
    padding-bottom: 10px;
  }

  .services-box-image {
    height:245px;
    width: 325px;
    margin-bottom: 0;
  }

  .services-box-text {
    padding: 0; 
    font-size: 14px;
  }

  .services-box-text-last {
    grid-column: 1 / -1; 
    font-size: 14px;
  }

  .services-box-text-author {
    width: 670px;
  }
}

@include for-desktop-up {

  .services-container {  
    width: 900px;
  }

  .services-box {
    width: 880px;
    gap: 20px;
    padding-bottom: 20px;
  }

  .services-box-image {
    height:370px;
    width: 420px;
    margin-bottom: 0;
  }

  .services-box-text {
    padding: 0; 
    font-size: 19px;
  }

  .services-box-text-last {
    font-size: 19px;
  }

  .services-box-text-author {
    width: 810px;
  }

  .services-box-text-author {
    width: 860px;
  }
}


@include for-wide-desktop {
  .services-container {  
    width: 1050px;
  }
}