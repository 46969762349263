@import './config/colors';
@import './config/breakpoints';
@import './config/fonts';

.welcome-container {
  background-image: url('../assets/lake.avif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  height: 700px;

  @include for-tablet {
    height: 600px;
  }

  @include for-desktop {
    height: 800px;
  }
}

