@import './config/colors';
@import './config/breakpoints';
@import './config/fonts';

.header {
  position: fixed;
  top: -100%;
  width: 100%;
  transition: top 0.3s ease-in-out; 
  z-index: 1000;
}

.header.is-visible {
  top: 0; 
}

.h-container.displayed {
  display: flex; 
}

.h-container.hidden {
  display: none; 
}

.h-container {
  height: 50px;
  background-color: $c-primary;
  color: $c-white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%; 
  z-index: 1000;
}

.hamburger {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 10;

  .hamburger-bar {
    width: 100%;
    height: 3px;
    background: $c-white;
    transition: all 0.3s linear;
  }
}

.hamburger.active .hamburger-bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg); 
}

.hamburger.active .hamburger-bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

// Styl dla mobilnego menu, które jest wysuwane
.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 40%;
  height: 100vh;
  background-color: $c-primary;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding-top: 60px;
  text-align: center;
}

.h-c-list {
  font-size: 18px;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.mobile-nav.active {
  transform: translateX(0);
}

@include for-mobile {
  .hamburger {
    display: flex;
  }

  .h-container {
    justify-content: space-between;
    padding: 0 20px;
  
  }

  .mobile-nav {
    display: block;
  }

  .desktop-nav {
    display: none;
  }
}

@include for-tablet {
  .hamburger {
    display: flex;
  }

  .h-container {
    justify-content: space-between;
    padding: 0 20px;
  
  }

  .mobile-nav {
    display: block;
  }

  .desktop-nav {
    display: none;
  }
}


@include for-desktop {
  .hamburger {
    display: none; 
  }

  .desktop-nav {
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .desktop-nav .h-c-list {
    display: block; 
  }

  .h-c-list a {
    color: $c-white; 
    text-decoration: none; 
    padding: 5px 0; 
    position: relative; 
    display: inline-block; 
  
    &::after {
      content: "";
      position: absolute;
      bottom: 0; 
      left: 0; 
      width: 0; 
      height: 2px; 
      background-color: $c-white;
      transition: width 0.3s linear; 
    }
  
    &:hover::after, &:focus::after {
      width: 100%; 
    }
  }
}

