.about-section{
  background: linear-gradient(to bottom, $c-theme, $c-primary);
  padding-block: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-container{
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.about-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
}


.about-box-image {
  width: 320px;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-image: url('../assets/mj.avif');
}

.about-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-name-h1 {
  padding-block: 10px;
}

.about-paragraph {
  text-align: start;
  font-size: 14px;
  padding-bottom: 10px;
}

.about-name-paragraph {
  font-size: 16px;
}

@include for-tablet-up {

  .about-container {
    width: 700px;
  }

  .about-header {
    font-size: $header-size-semi-large;
  }

  .about-box {
    width: 650px;
    align-items: end;
  }

  .about-text-box {
    padding-top: 15px;
  }

  .about-box-with-image {
    flex-direction: row;

    .about-paragraph-1 {
      padding: 0;
    }
  }

  .about-box-image {
    width: 350px;
    height: 530px;
  }

  .about-text-box-with-image {
    width: 50%;
    padding-left: 15px;
  }

  .name {
    padding-bottom: 0;

    .about-name-h1 {
      padding-bottom: 15px;
    }
  }


  .about-paragraph {
    padding-bottom: 15px;
  }
}

@include for-desktop-up {

  .about-container {
    width: 900px;
  }

  .about-box {
    width: 880px;
  }

   .about-box-image {
    width: 430px;
    height: 660px;
  }

  .about-text-box {
    padding-top: 20px;
  }

  .about-text-box-with-image {
    padding-left: 20px;
  }

  .about-paragraph{
    font-size: 19px;
    padding-bottom: 20px;
  }

  .name {
    padding: 0;

    .about-name-h1 {
      padding-bottom: 20px;
    }
  }

  .about-name-paragraph {
    font-size: 24px;
  }

  .about-paragraph-1 {
    font-size: 19px;
  }
}

@include for-wide-desktop {
  .about-section{

    h2 {
      font-size: $header-size-semi-large;
      padding: 20px 0 30px 0;
    }
  }

  .about-container {
    padding: 50px 0;
    width: 1050px;
  }
}