.type__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.type__container {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.type__box {
  width: 330px;
  height: auto;
  min-height: 330px;
  margin: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out; 
  position: relative;
}

.type__box__title {
  font-family: $f-main-regular;
  font-weight: 600;
  color: $c-black;
  text-align: center;
  padding-bottom: 10px;
}

.type__box__text {
  background-color: $c-white;
  color: $c-black; 
  display: flex;
  justify-content: center;
  text-align: start;
  width: 250px;
}

.type__box.expanded {
  max-height: 1000px;
  padding-block: 30px;
}

@media (min-width: 768px) {
  .type__container {
    width: 700px;
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .type__box {
    padding: 15px;
    font-size: $font-size-medium;
  }

  .type__box__text {
    width: 260px;
  }
}

@media (min-width: 1024px) {
  .type__container{
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
    margin-block: 30px;
  }

  .type__box {
    width: 400px;
    min-height: 400px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .type__box:hover {
    transform: scale(1.03);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }

  .type__box__title {
    font-size: 19px;
  }

  .type__box__text {
    width: 280px;
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .type__container{
    width: 1450px;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
  }

  .type__box {
    width: 310px;
    min-height: 310px;
    justify-content: flex-start;
  }

  .type__box__title {
    margin-top: 60px;
  }

  
  .type__box__text {
    width: 250px;
    }
}

.type__box.expanded {
  .type__box__text {
    margin-bottom: 60px;
  }
}
