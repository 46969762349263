/* Reset CSS */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%; /* 1rem = 16px */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Usuwa dekorację tekstu z linków i ustawia kolor */
a {
  text-decoration: none;
  color: inherit;
}

/* Usuwa obramowanie z obrazków i obszarów mapy obrazków */
img,
area {
  border: 0;
}

/* Usuwa obramowanie, padding i zmienia typ listy na none dla elementów formularza */
input,
button,
textarea,
select {
  font: inherit;
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  vertical-align: middle;
}

/* Usuwa obramowanie tabeli */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Usuwa domyślne style przycisków i pól formularzy w Firefox */
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Usuwa dodatkowy margines w Safari */
button,
input {
  overflow: visible;
}

/* Usuwa domyślne style przycisku w iOS */
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none; /* Dla Safari i Chrome */
  -moz-appearance: none;    /* Dla Firefox */
  appearance: none;         /* Standardowa właściwość */
}

/* Usuwa domyślny obramowanie iframe */
iframe {
  border: 0;
}
